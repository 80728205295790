

















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Inject } from 'vue-property-decorator'
import CardHover from '../../../components/card-hover.vue'
import { PoolStore } from '../stores/pool-store'
import poolState from './pool-state.vue'
import { fileHelpers } from '@/helpers/file-helper'
import { AppProvider } from '@/app-providers'

@Observer
@Component({ components: { poolState, CardHover } })
export default class FeaturedPoolItem extends Vue {
  @Inject() providers!: AppProvider
  @Prop({ required: true, default: null }) model!: PoolStore
  get url() {
    return fileHelpers.getApiFileUrl(this.model?.pool?.file)
  }
}
