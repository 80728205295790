














import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PoolState, formatDuration } from '../business/swap-contract.business'
import moment from 'moment'
@Observer
@Component({ components: {} })
export default class FeaturedPoolItem extends Vue {
  @Prop() state?: PoolState

  text = ''
  time = ''
  warn = false

  @Watch('state', { immediate: true }) onStateChanged(state: PoolState) {
    if (!state) return
    const { ended, started, filled, startDuration, endDuration, startDate, endDate, isTBAStartDate } = state
    if (ended) {
      this.text = filled ? 'Filled' : 'Ended'
      this.warn = !filled
      this.time = filled
        ? `Published at ${moment(endDate).format('DD/MM/YYYY HH:mm:ss')}`
        : `Ended at ${moment(endDate).format('DD/MM/YYYY HH:mm:ss')}`
      // this.warn = !filled
    } else if (started) {
      this.text = filled ? 'Filled' : `in ${formatDuration(endDuration)}`
      this.time = `End at ${moment(endDate).format('DD/MM/YYYY HH:mm:ss')}`
    } else {
      if (isTBAStartDate) {
        this.text = 'TBA'
        this.time = 'TBA'
      } else {
        this.text = `in ${formatDuration(startDuration)}`
        this.time = `Start at ${moment(startDate).format('DD/MM/YYYY HH:mm:ss')}`
      }
    }
  }

  get backgroundClass() {
    return this.warn ? 'error-background' : 'success-background'
  }
  get circleClass() {
    return this.warn ? 'red' : 'green'
  }
  get textClass() {
    return this.warn ? 'red--text' : 'green--text'
  }
}
