



















































































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHover from '../../../components/card-hover.vue'
import { PoolStore } from '../stores/pool-store'
import poolState from './pool-state.vue'

@Observer
@Component({ components: { poolState, CardHover } })
export default class FeaturedPoolItem extends Vue {
  showMore = false

  @Prop({ required: true, default: null }) model!: PoolStore

  mounted() {
    this.model.loadDataIfNeed()
  }

  clickOnCard() {
    if (this.model.web) {
      window.open(this.model.web)
    }
  }
  isSocial() {
    return (
      this.model.medium ||
      this.model.telegram ||
      this.model.web ||
      this.model.facebook ||
      this.model.instagram ||
      this.model.discord ||
      this.model.reddit ||
      this.model.telegramChat ||
      this.model.twitter ||
      this.model.github ||
      this.model.youtube
    )
  }
}
